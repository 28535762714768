import React from 'react'
import './IntroBanner.css'
import OwlCarousel from 'react-owl-carousel';
import $ from 'jquery';

const IntroBanner = () => {

  const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
      1000: {
        items: 3
      }
    }
  };





  return (
    <div className="new-app-main-banner-area">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="new-app-main-banner-content">
              <div className="content">
                <div className="name-big-text">Pixobuy</div>
                <span className="sub-title">#Feel Free To E-Commerce</span>
                <h1>Manage All Of Your Product Price Using A Pixobuy</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id
                  tincidunt eifend odio viverra diam aliquet donec again.
                </p>
                <div className="app-btn-box">
                  <a href="#" className="applestore-btn" target="_blank">
                    <img src={require("../../Assets/partner/apple-store.png")} alt="image" />
                    Download on the
                    <span>Apple Store</span>
                  </a>
                  <a href="#" className="playstore-btn" target="_blank">
                    <img src={require("../../Assets/partner/play-store.png")} alt="image" />
                    Get It On
                    <span>Google Play</span>
                  </a>
                </div>
                <div className="content-shape">
                  <img
                    src={require("../../Assets/banner/content-shape.png")}
                    alt="image"
                  />
                </div>
              </div>
              <div className="new-app-trusted-by">
                <div className="row align-items-center">
                  <div className="col-lg-2 col-md-12">
                    <span className="title">Trusted by:</span>
                  </div>
                  <div className="col-lg-10 col-md-12">

                    <OwlCarousel className='new-app-trusted-by-slides owl-theme' {...options}>
                      <div className='item'><img
                        src={require("../../Assets/partner/partner1.png")}
                        alt="partner"
                      /></div>
                      <div className='item'><img
                        src={require("../../Assets/partner/lockup.svg")}
                        alt="partner"
                      /></div>
                      <div className='item'><img
                        src={require("../../Assets/partner/partner3.png")}
                        alt="partner"
                      /></div>
                    </OwlCarousel>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div
              className="new-app-main-banner-image aos-init"
              data-aos="fade-up"
              data-aos-duration={2000}
            >
              <img src={require("../../Assets/banner/banner-1.png")} alt="image" />
              <div className="wrap-shape-1">
                <img src={require("../../Assets/banner/shape-1.png")} alt="image" />
              </div>
              <div className="wrap-shape-2">
                <img src={require("../../Assets/banner/shape-2.png")} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="new-app-banner-bg-shape">
        <img src={require("../../Assets/banner/banner-shape.png")} alt="image" />
      </div>
      <div className="new-app-banner-strock-shape">
        <img src={require("../../Assets/banner/strock.png")} alt="image" />
      </div>
    </div>

  )
}

export default IntroBanner