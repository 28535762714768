import React from 'react'
import './About.css'

const About = () => {
  return (
    <div className="app-about-area pb-100">
  <div className="container-fluid">
    <div className="row align-items-center">
      <div className="col-lg-6 col-md-12">
        <div className="app-about-image">
          <img  src={require("../../Assets/about.png")} alt="image" />
        </div>
      </div>
      <div className="col-lg-6 col-md-12">
        <div className="app-about-content">
          <div className="big-text">About Us</div>
          <span className="sub-title">ABOUT US</span>
          <h2>Most Probably You Are Getting Best App Ever</h2>
          <p>
            Cloud based storage for your data backup just log in with your mail
            account from play store and using whatever you want for your
            business purpose orem ipsum dummy text. Never missyour chance its
            just began.
          </p>
          <ul className="list">
            <li>
              <div className="icon">
                <i className="ri-award-line" />
              </div>
              <h3>Trusted and Reliable</h3>
              <p>
                Most provabily best you can trust on it, just log in with your
                mail account from play store and using whatever you want for
                your business.
              </p>
            </li>
            <li>
              <div className="icon bg2">
                <i className="ri-download-cloud-2-line" />
              </div>
              <h3>Cloud Storage</h3>
              <p>
                Just log in with your mail account from play store and using
                whatever you want for your business purpose.
              </p>
            </li>
          </ul>
          <div className="btn-box">
            <a href="app-download.html" className="default-btn">
              Start Free Trial
            </a>
            <a href="features-1.html" className="link-btn">
              See All Features
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="app-about-shape-1">
    <img src={require("../../Assets/shape-1.png")} alt="image" />
  </div>
  <div className="app-about-shape-2">
    <img src={require("../../Assets/shape-2.png")} alt="image" />
  </div>
  <div className="app-about-shape-3">
    <img src={require("../../Assets/shape-3.png")} alt="image" />
  </div>
</div>

  )
}

export default About