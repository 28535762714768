import React from 'react'
import './Feature.css'

const Feature = () => {
    return (
        <div className="features-area pt-100 pb-75">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                        <div className="features-card">
                            <div className="icon">
                                <i className="ri-smartphone-line" />
                                <div className="number">1</div>
                            </div>
                            <h3>User Friendly</h3>
                            <p>Most Provably best dashboard design for your business you can.</p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                        <div className="features-card">
                            <div className="icon">
                                <i className="ri-award-line" />
                                <div className="number">2</div>
                            </div>
                            <h3>Award-Winning App</h3>
                            <p>Most Provably best dashboard design for your business you can.</p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                        <div className="features-card">
                            <div className="icon">
                                <i className="ri-fingerprint-line" />
                                <div className="number">3</div>
                            </div>
                            <h3>Privacy Protected</h3>
                            <p>Most Provably best dashboard design for your business you can.</p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                        <div className="features-card">
                            <div className="icon">
                                <i className="ri-vip-diamond-line" />
                                <div className="number">4</div>
                            </div>
                            <h3>Lifetime Update</h3>
                            <p>Most Provably best dashboard design for your business you can.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Feature