
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';
import IntroBanner from './Components/IntroBanner/IntroBanner';
import { useEffect } from 'react';
import Navbar from './Components/Navbar/Navbar';
import Feature from './Components/Feature/Feature';
import About from './Components/About/About';

function App() {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
    });
  }, []);

  return (
    <>
    <Navbar/>
    <IntroBanner/>
    <Feature/>
    <About/>
    </>
  );
}

export default App;
